@font-face {
  font-family: 'Satoshi-Bold';
  src: url('/public/fonts/Satoshi-Bold.woff2') format('woff2'),
       url('/public/fonts/Satoshi-Bold.woff') format('woff'),
       url('/public/fonts/Satoshi-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Black';
  src: url('/public/fonts/Satoshi-Black.woff2') format('woff2'),
       url('/public/fonts/Satoshi-Black.woff') format('woff'),
       url('/public/fonts/Satoshi-Black.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}



